import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import classNames from 'classnames';
import css from './ItemView.scss';
import Placeholder from './Placeholder';
import ItemCard from './ItemCard';
import ItemPostPlaceholder from './ItemPostPlaceholder';
import SectionTitle from './Sections/SectionTitle';
import { withConfig } from 'HOCs/withConfig/withConfig';
import { CARS_CATEGORY_PAGE_SIZE, ITEM_SOURCE } from 'Constants/items';
import { noop } from 'Helpers/function';
import { isMobile } from 'Helpers/devices';
import AdvertisingComponent from 'Components/AdvertisingComponent/AdvertisingComponent';
import { middleAdContainerPattern, middleAdContainerPattern2 } from '../../../pages/Listing/utils';
import { CONFIG } from './constants';
import { GRID } from 'Constants/constants';
import { RESULTS_PAGE } from 'Constants/baxter';
import { CONFIG as listConfig } from 'Constants/config';
import CarsPlaceholder from './CarsPlaceholder';
import { isAutosAvailable } from './helpers';
import { getUserInfoByCategory } from 'Helpers/users';

const itemPostPlaceholderPosition = (config, placeholderOrigin) => {
    const { enabled, home, listing } = config.get(CONFIG.ITEM_POST_PLACEHOLDER);

    if (enabled && placeholderOrigin) {
        return placeholderOrigin === 'home' ? home.itemsBefore : listing.itemsBefore;
    }
    return null;
};

const renderPlaceholders = (expectedItems, visualizationType, placeholderImagesStyle) => {
    return Array.from(Array(expectedItems).keys()).map(index => (
        <Placeholder
            key={ `placeholder_${index}` }
            visualizationType={ visualizationType }
            placeholderImagesStyle={ placeholderImagesStyle }
        />
    ));
};

const renderItemPlaceholder = (marketConfig, items, locationId, itemPostPlaceholderOrigin, placeholderPosition, visualizationType) => {
    if (isAutosAvailable(marketConfig, items, locationId.toString()) && itemPostPlaceholderOrigin !== listConfig.HOME) {
        return (
            <CarsPlaceholder
                key={ listConfig.CARS_PLACEHOLDER }
                placeholderPosition={ placeholderPosition }
                items={ items }
                shouldRun={ false } /* TO BE REMOVED */
            />
        );
    }
    return (
        <ItemPostPlaceholder
            key="postPlaceholder"
            itemPostPlaceholderOrigin={ itemPostPlaceholderOrigin }
            visualizationType={ visualizationType }
        />
    );
};

const renderItems = (items, sectionsItems, sectionsOffset, props) => {
    const {
        type, enabledRealImpressions, onViewItem, visualizationType,
        showStatistics, listingType, getListingDescription, filters, onItemClick,
        source, isAdSuggested, showInspectionTag, extraTrackAttr, isClassified,
        listingBodyType, isShowVideoTag, showSponseredTag, showDealerTag, fromPage, isDealerPreview, isDealerProfile,
        isPaginated, location, user
    } = props;

    return items.map((item, itemIndex) => {
        let newItemIndex = itemIndex;

        if (isPaginated) {
            const currentPage = location?.query?.page > 0 ? location.query.page - 1 : location.query.page || 0;

            newItemIndex = itemIndex + currentPage * CARS_CATEGORY_PAGE_SIZE;
        }
        const hasSection = isPaginated ? sectionsOffset && sectionsOffset.includes(newItemIndex) : sectionsOffset && sectionsOffset.includes(itemIndex);
        const videoTag = (item?.video_status && isShowVideoTag);
        const dealer = getUserInfoByCategory(user, item?.category_id);
        const userType = (dealer.dealer_type || '').toLowerCase();

        return (
            <React.Fragment key={ `${item.id}_${itemIndex}` }>
                {hasSection && (
                    <SectionTitle section={ isPaginated ? sectionsItems.find(section => section.offset === newItemIndex) : sectionsItems.find(section => section.offset === itemIndex) } />
                )}
                <ItemCard
                    key={ item.id }
                    item={ item }
                    userType={ userType }
                    type={ type }
                    enabledRealImpressions={ enabledRealImpressions }
                    onView={ onViewItem }
                    visualizationType={ visualizationType }
                    showStatistics={ showStatistics }
                    listingType={ listingType }
                    getListingDescription={ getListingDescription }
                    filters={ filters }
                    onItemClick={ onItemClick }
                    source={ source }
                    isAdSuggested={ isAdSuggested }
                    chosenOption={ itemIndex }
                    showInspectionTag={ showInspectionTag }
                    extraTrackAttr={ extraTrackAttr }
                    isClassified={ isClassified }
                    listingBodyType={ listingBodyType }
                    isShowVideoTag={ videoTag }
                    showSponseredTag={ showSponseredTag }
                    showDealerTag={ showDealerTag }
                    fromPage={ fromPage }
                    isDealerPreview={ isDealerPreview }
                    isDealerProfile={ isDealerProfile }
                />
            </React.Fragment>
        );
    });
};

const renderBanners = (source, slot, css, baxterPageName, categoryTree) => {
    let dynamicAdId = '';

    if (source === ITEM_SOURCE.HOME) {
        const slotToDynamicAdIdMap = {
            middle1: 'home_1',
            middle2: 'home_2',
            middle3: 'home_3',
            middle4: 'home_4',
            middle5: 'home_default',
            middle6: 'home_default'
        };

        dynamicAdId = slotToDynamicAdIdMap[slot] || 'home_default';
    }
    return (
        <li className={ css.middleAdContainer } key={ slot }>
            <AdvertisingComponent
                slot={ slot }
                page={ baxterPageName }
                category={ categoryTree }
                dynamicAdId={ dynamicAdId }
            />
        </li>
    );
};

const insertMiddleAds = (itemsList, source, css, categoryTree, middleAdPattern2, marketConfig) => {
    const isMiddleAdPage = (source === ITEM_SOURCE.HOME && marketConfig.get(CONFIG.MIDDLE_AD_HOME))
        || (marketConfig.get(CONFIG.MIDDLE_AD_CATEGORY) && (source === ITEM_SOURCE.BROWSE || source === ITEM_SOURCE.SEARCH));

    if (isMobile && isMiddleAdPage && itemsList?.length) {
        const baxterPageName = source === ITEM_SOURCE.HOME ? ITEM_SOURCE.HOME : RESULTS_PAGE;
        const adIndices = middleAdPattern2 ? middleAdContainerPattern2(itemsList.length) : middleAdContainerPattern(itemsList.length);

        for (let index = 0; index < adIndices.length; index++) {
            itemsList.splice(adIndices[index].index, 0, renderBanners(source, `middle${adIndices[index].adsCounter}`, css, baxterPageName, categoryTree));
        }
    }
    return itemsList;
};

export const ItemViewListing = ({
    items, expectedItems, isFetching, showStatistics, similarAdsData,
    type, enabledRealImpressions, onViewItem, isAdSuggested,
    visualizationType, fourColumns, isClassified, itemPostPlaceholderOrigin,
    sectionsItems, listingType, getListingDescription, showInspectionTag,
    filters, placeholderImagesStyle, marketConfig, onItemClick, source, loadMoreBtn, extraTrackAttr, categoryTree, userType, locationId, listingBodyType, isShowVideoTag, showSponseredTag, showDealerTag, fromPage, isDealerPreview, isDealerProfile,
    location, isPaginated,
    categoryID
}) => {
    const loadingItems = renderPlaceholders(expectedItems, visualizationType, placeholderImagesStyle);
    const placeholderPosition = itemPostPlaceholderPosition(marketConfig, itemPostPlaceholderOrigin);
    const sectionsOffset = sectionsItems && sectionsItems.map(({ offset }) => offset);

    const itemsList = renderItems(items, sectionsItems, sectionsOffset, {
        userType, type, enabledRealImpressions, onViewItem, visualizationType,
        showStatistics, listingType, getListingDescription, filters, onItemClick,
        source, isAdSuggested, showInspectionTag, extraTrackAttr, isClassified,
        listingBodyType, isShowVideoTag, showSponseredTag, showDealerTag, fromPage, isDealerPreview, isDealerProfile, location, isPaginated,
        categoryID
    });

    if (placeholderPosition && items.length && !similarAdsData?.length) {
        const itemPlaceholder = renderItemPlaceholder(marketConfig, items, locationId, itemPostPlaceholderOrigin, placeholderPosition, visualizationType);

        itemsList.splice(placeholderPosition, 0, itemPlaceholder);
    }

    const updatedItemsList = insertMiddleAds(itemsList, source, css, categoryTree, marketConfig.get(CONFIG.MIDDLE_AD_PATTERN2), marketConfig);

    if (isFetching) {
        updatedItemsList.push(loadingItems);
    }

    const columns = (visualizationType === GRID && fourColumns)
        ? css[`${visualizationType}sSideByFour`]
        : css[`${visualizationType}sSide`];

    return (
        <ul className={ classNames(css[`${visualizationType}Items`], columns, { [css.fetchingList]: isFetching }) } data-aut-id="itemsList1">
            {updatedItemsList}
            <li className={ css.loadMoreBtnContainer }>{loadMoreBtn}</li>
        </ul>
    );
};

ItemViewListing.propTypes = {
    items: PropTypes.array.isRequired,
    similarAdsData: PropTypes.array,
    type: PropTypes.string,
    isShowVideoTag: PropTypes.bool,
    onViewItem: PropTypes.func,
    isFetching: PropTypes.bool,
    fourColumns: PropTypes.bool,
    categoryTree: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    isClassified: PropTypes.bool,
    showStatistics: PropTypes.bool,
    expectedItems: PropTypes.number,
    enabledRealImpressions: PropTypes.bool,
    itemPostPlaceholderOrigin: PropTypes.string,
    visualizationType: PropTypes.oneOf(['grid', 'big', 'list']),
    sectionsItems: PropTypes.arrayOf(
        PropTypes.shape({
            offset: PropTypes.number,
            location: PropTypes.shape({
                id: PropTypes.number,
                name: PropTypes.string,
                type: PropTypes.string
            })
        })
    ),
    listingType: PropTypes.string,
    getListingDescription: PropTypes.func,
    filters: PropTypes.object,
    placeholderImagesStyle: PropTypes.string,
    marketConfig: PropTypes.shape({
        get: PropTypes.func.isRequired
    }).isRequired,
    onItemClick: PropTypes.func,
    isAdSuggested: PropTypes.bool,
    source: PropTypes.string,
    loadMoreBtn: PropTypes.node,
    showInspectionTag: PropTypes.bool,
    extraTrackAttr: PropTypes.object,
    userType: PropTypes.string,
    locationId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    listingBodyType: PropTypes.string,
    showSponseredTag: PropTypes.bool,
    showDealerTag: PropTypes.bool,
    fromPage: PropTypes.string,
    isDealerPreview: PropTypes.bool,
    isDealerProfile: PropTypes.bool,
    location: PropTypes.shape({
        query: PropTypes.shape({
            page: PropTypes.number
        })
    }),
    isPaginated: PropTypes.bool,
    categoryID: PropTypes.string
};

ItemViewListing.defaultProps = {
    expectedItems: 20,
    fourColumns: false,
    isClassified: false,
    isShowVideoTag: false,
    visualizationType: 'grid',
    itemPostPlaceholderOrigin: '',
    listingType: 'items',
    similarAdsData: [],
    onItemClick: noop,
    getListingDescription: noop,
    filters: {},
    placeholderImagesStyle: '',
    isAdSuggested: false,
    source: ITEM_SOURCE.OTHER,
    loadMoreBtn: null,
    showInspectionTag: false,
    extraTrackAttr: {},
    locationId: '',
    listingBodyType: '',
    showSponseredTag: false,
    showDealerTag: false,
    fromPage: '',
    location: {
        query: {}
    }
};

export default compose(withConfig('marketConfig'))(ItemViewListing);
